import React from 'react';
import styled from '@emotion/styled';
import { TextSet } from 'components/molecules/textSet';
import { mq } from 'style/variables';
import { ShifterProjectsJpDataAcfHighlightsContentsComment } from 'types/graphql-types';
import colors from 'style/colors';

export type ProjectEvaluateTextCardProps = NonNullable<ShifterProjectsJpDataAcfHighlightsContentsComment> & {
  notes: Array<string>;
};

const ProjectEvaluateTextCardContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 60px 48px;
  background-color: ${colors.black02};
  color: ${colors.white01};
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2em;
  ${mq.onlysp} {
    padding: 32px;
    font-size: 24px;
    line-height: 1.2em;
  }
`;

const ProjectEvaluateTextCardNotes = styled.div``;

const ProjectEvaluateTextCardNoteItem = styled.p`
  line-height: 1em;
  font-size: 12px;
  &::before {
    content: '※';
  }
`;

export const ProjectEvaluateTextCard: React.FC<ProjectEvaluateTextCardProps> = ({ heading, text, notes }) => {
  return (
    <ProjectEvaluateTextCardContainer>
      <TextSet noMargin={true}>{heading}</TextSet>
      <TextSet noMargin={true}>{text}</TextSet>
      <ProjectEvaluateTextCardNotes>
        {notes.length > 0 &&
          notes.map((item, index) => (
            <ProjectEvaluateTextCardNoteItem key={index}>{item}</ProjectEvaluateTextCardNoteItem>
          ))}
      </ProjectEvaluateTextCardNotes>
    </ProjectEvaluateTextCardContainer>
  );
};
