import styled from '@emotion/styled';
import { EnHeading } from 'components/atoms/enHeading';
import React from 'react';
import colors from 'style/colors';
import fonts from 'style/fonts';
import { contentWrapper } from 'style/mixin';
import sizes from 'style/sizes';
import { mq } from 'style/variables';
import { Maybe, ShifterProjectsJpDataAcfProcess } from 'types/graphql-types';

export type ProjectDetailProcessProps = { process: Maybe<ShifterProjectsJpDataAcfProcess>[] };

const Container = styled.div<ProjectDetailProcessProps>`
  opacity: 1;
  ${contentWrapper}
  ${mq.onlypc} {
    margin-bottom: ${sizes.margin[180]};
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[100]};
  }
`;

const ProjectDetailProcessHeading = styled.div`
  margin-bottom: 32px;
  ${mq.onlysp} {
    margin-bottom: 24px;
  }
`;

const ProjectDetailProcessGrid = styled.div`
  display: grid;
  ${mq.onlypc} {
    column-gap: 12px;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
  ${mq.onlysp} {
    row-gap: 12px;
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
  }
`;

const ProjectDetailProcessItem = styled.div`
  background-color: ${colors.gray08};
  padding: 32px;
  ${mq.onlypc} {
    width: 100%;
    height: 253px;
  }
`;
const ProjectDetailProcessItemHeading = styled.div`
  font-family: ${fonts.enGothic};
  font-size: 20px;
  font-weight: 500;
  line-height: 1em;
  margin-bottom: 18px;
  ${mq.onlysp} {
    font-size: 18px;
    margin-bottom: 8px;
  }
`;
const ProjectDetailProcessItemBody = styled.div`
  font-family: ${fonts.jaGothic};
  font-size: 26px;
  font-weight: 600;
  line-height: 1.48em;
  ${mq.onlysp} {
    font-size: 20px;
  }
`;

export const ProjectDetailProcess: React.FC<ProjectDetailProcessProps> = props => {
  return (
    <Container {...props}>
      <ProjectDetailProcessHeading>
        <EnHeading size="section">Process</EnHeading>
      </ProjectDetailProcessHeading>
      <ProjectDetailProcessGrid>
        {props.process &&
          props.process.map((item, index) => (
            <ProjectDetailProcessItem key={`project-process-${index}`}>
              {item?.title && <ProjectDetailProcessItemHeading dangerouslySetInnerHTML={{ __html: item?.title }} />}
              <ProjectDetailProcessItemBody dangerouslySetInnerHTML={{ __html: item?.text || '' }} />
            </ProjectDetailProcessItem>
          ))}
      </ProjectDetailProcessGrid>
    </Container>
  );
};
