import { AwardProps, htmlscd } from 'components/atoms/award';
import Img from 'components/atoms/img/';
import { JaHeading as JaHeadingBase } from 'components/atoms/jaHeading';
import { List } from 'components/atoms/list';
import { Text as TextBase } from 'components/atoms/text';
import { Video } from 'components/atoms/video';
import { FullImg } from 'components/molecules/fullImg';
import { FullVideo } from 'components/molecules/fullVideo';
import { MediaGrid } from 'components/molecules/mediaGrid';
import { ProjectCard } from 'components/molecules/projectCard';
import { TextSet } from 'components/molecules/textSet';
import { TopicsSectionWrap } from 'components/molecules/topicsSectionWrap';
import { Youtube } from 'components/molecules/youtube';
import { ContentInner } from 'components/organisms/contentInner';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import { DetailContent, ImgsObject } from 'components/organisms/detailContent';
import { DetailContentIncludingComponent } from 'components/organisms/detailContentIncludingComponent';
import { DetailMain } from 'components/organisms/detailMain';
import GalleryLoopSlide, { GalleryLoopSlideType } from 'components/organisms/galleryLoopSlide';
import GallerySlide, { ContentsObject } from 'components/organisms/gallerySlide';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import { NumbersObject, ProjectDetailNumbers } from 'components/organisms/projectDetailNumbers';
import { CreditObject, ProjectDetailOtherData } from 'components/organisms/projectDetailOtherData';
import { ProjectsBackToWrapBottom } from 'components/organisms/projectsBackToWrapBottom';
import RangeSlide from 'components/organisms/rangeSlide';
import { graphql } from 'gatsby';
import { difference, uniqBy } from 'lodash';
import shuffle from 'lodash/shuffle';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import sizes from 'style/sizes';
import { mq } from 'style/variables';
import { ProjectsDetailQuery } from 'types/graphql-types';
import SEO from 'util/seo';
import {
  isAvailableAbstruct,
  isAvailableAwards,
  isAvailableHighlightsArea,
  isAvailableLinkArea,
  isAvailableProcess,
  isProjectEvaluateAreaContents,
  isShifterProjectsDataAcfModulesFeature,
  isShifterProjectsJpData,
  isShifterProjectsJpDataAcfModulesFeature,
} from 'util/typeGuards/project';

import styled from '@emotion/styled';
import { ProjectEvaluateArea } from '../components/organisms/projectEvaluateArea/index';
import { ProjectsDetailContext } from '../../gatsby-node/createProjectsDetailPages';
import { ProjectDetailProcess } from 'components/organisms/projectDetailProcess';
import { ProjectDetailAbstract as ProjectDetailAbstractBase } from 'components/organisms/projectDetailAbstract';
import { contentWrapper, text } from 'style/mixin';
import { Button } from 'components/atoms/button';
import { EnHeading } from 'components/atoms/enHeading';
import { isAvailableAcf } from '../util/typeGuards/project/index';
import { css } from '@emotion/core';
import { ProjectEvaluateMessageCard } from 'components/molecules/projectEvaluateMessageCard';
import { useEffect, useRef, useState } from 'react';
import { useIsSp } from 'util/useIsSp';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema

interface IndexPageProps {
  pageContext: ProjectsDetailContext;
  data: ProjectsDetailQuery;
}

// Project詳細上部エリアのパーツレイアウト用 ----- ここから -----
const TopArea = styled.div`
  ${contentWrapper}
  display: grid;
  ${mq.onlypc} {
    grid-template-columns: 687px 1fr;
    column-gap: 118px;
    margin-bottom: ${sizes.margin[180]};
  }
  ${mq.onlysp} {
    row-gap: 64px;
    margin-bottom: ${sizes.margin[100]};
  }
`;
const TopAreaRightSide = styled.div`
  display: grid;
  ${mq.onlypc} {
    align-items: end;
    grid-template-rows: 1fr;
    row-gap: 80px;
  }
  ${mq.onlysp} {
    width: 311px; // Awardsを4列にしたいがため無理やり幅を規定
    row-gap: 52px;
    margin: auto;
    text-align: center;
  }
`;
const TopAreaSectionHeading = styled.div`
  margin-bottom: 32px;
  ${mq.onlysp} {
    margin-bottom: 24px;
  }
`;
const TopAreaSection = styled.div`
  height: min-content;
`;
const TopAreaLinkList = styled.div`
  display: grid;
  row-gap: 16px;
  ${mq.onlysp} {
    justify-content: center;
  }
`;
const TopAreaAwardList = styled.div<{ itemCount: number }>`
  display: grid;

  ${mq.onlypc} {
    grid-template-columns: repeat(3, 80px);
    grid-auto-rows: 80px;
    gap: 40px;
  }
  ${mq.onlysp} {
    justify-content: center;
    grid-template-columns: ${({ itemCount }) => (itemCount <= 4 ? `repeat(${itemCount}, 80px)` : `repeat(4, 80px)`)};
    grid-auto-rows: 64px;
    gap: 16px;
  }

  img {
    object-fit: contain;
  }
`;
const ProjectDetailAbstract = styled(ProjectDetailAbstractBase)`
  & + & {
    margin-top: 100px;

    ${mq.onlysp} {
      margin-top: 64px;
    }
  }
`;
const Text = styled(TextBase)`
  ${mq.onlysp} {
    ${text(16, 1.8)}
  }
`;
interface JaHeadingProps {
  isInContents?: boolean;
}
const JaHeading = styled(JaHeadingBase)<JaHeadingProps>`
  ${({ isInContents }) =>
    isInContents
      ? css`
          ${mq.onlysp} {
            font-size: 24px;
          }
        `
      : css`
          -webkit-text-stroke: 0.3px currentColor;
          text-stroke: 0.3px currentColor;
        `}
`;
// Project詳細上部エリアのパーツレイアウト用 ----- ここまで -----

const ProjectListWrap = styled.div`
  margin-top: ${sizes.margin[177]};
  margin-bottom: ${sizes.margin[177]};
  ${mq.onlysp} {
    margin-top: 108px;
    margin-bottom: 108px;
  }
`;

const ProjectEvaluatePicAndMessages = styled.div`
  width: 100%;

  ${mq.onlypc} {
    display: grid;
    grid-template-columns: 1fr 720px;
    grid-template-rows: repeat(2, auto);
    max-width: 1920px;
    margin: 0 auto;
    margin-bottom: ${sizes.margin[180]};
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[100]};
  }
`;
interface ProjectEvaluateWrapperProps {
  height: string;
}
const ProjectEvaluateWrapper = styled.div<ProjectEvaluateWrapperProps>`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  ${mq.onlypc} {
    ${({ height }) =>
      height &&
      css`
        height: ${height};
      `}
  }
  // NOTE:親から直接cssを設定
  > * {
    height: 100%;
  }
`;

const ProjectEvaluatePic = styled(Img)``;

const ProjectEvaluateMessagesFirst = styled.div`
  grid-column: 2/ 3;
  grid-row: 1 / 2;
  height: min-content;
`;

const ProjectEvaluateMessagesSecond = styled.div`
  grid-column: 2/ 3;
  grid-row: 2 / 3;
  height: min-content;
`;

const Pages: React.FC<IndexPageProps> = ({ data }) => {
  const otherProjectsOldLayout = data.otherProjects.edges;
  const otherProjectsNewLayout = data.otherProjectsJp.edges;
  const otherProjects = otherProjectsNewLayout.concat(otherProjectsOldLayout);
  const acf = data.shifterProjectsJpData?.acf || data.shifterProjectsData?.acf;
  const title = data.shifterProjectsData?.title || data.shifterProjectsJpData?.title;
  const currentService = acf?.services?.map(service => service?.post_title);
  const isNewLayout = isShifterProjectsJpData(data);

  const sameClientProjects = otherProjects.filter(
    project => project.node.acf?.client_name?.ja === acf?.client_name?.ja
  );
  const sameServiceProjects = shuffle(
    uniqBy(
      otherProjects
        .filter(project => project.node.acf?.client_name?.ja !== acf?.client_name?.ja)
        .filter(project => project?.node.acf?.services?.some(service => currentService?.includes(service?.post_title))),
      'node.id'
    )
  );
  const remainingProjects = difference(otherProjects, [...sameClientProjects, ...sameServiceProjects]);
  const showOtherProjects = [...sameClientProjects, ...sameServiceProjects, ...remainingProjects].slice(0, 12);
  const isAhamo = data.shifterProjectsData?.slug === 'docomo-01';
  const isSp = useIsSp();
  const [messageHeight, setMessageHeight] = useState('auto');
  const firstMessageRef = useRef<HTMLDivElement>(null);
  const secondMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (firstMessageRef.current && secondMessageRef.current) {
      const height = firstMessageRef.current.offsetHeight + secondMessageRef.current.offsetHeight;
      setMessageHeight(typeof height === 'number' ? `${height + 1}px` : 'auto'); // borderの影響か、見た目的に高さが合わなかったので微調整
    }
  }, [isSp]);

  return (
    <>
      <SEO title={`${title?.rendered}｜Projects`} />
      <Helmet
        bodyAttributes={{
          'data-page': 'project-detail',
        }}
      />
      <DetailMain
        imgpath={[acf?.kv_sp || `noimage/img-card-kv-sp.jpg`, acf?.kv || `noimage/img-card-kv.jpg`]}
        maskImgPath={[acf?.kv_mask_sp, acf?.kv_mask]}
        alt={''}
        client={acf?.client_abbr?.ja || undefined}
        work={acf?.product?.ja || ''}
        outlines={(acf?.outline as string[]) || undefined}
        visitUrl={acf?.url || undefined}
        overviewText={acf?.overview_text?.ja || ''}
        isNewLayout={isNewLayout}
        isShowSolutionArea={isAhamo} // 旧レイアウト時、ahamoのときだけdownloadの導線を表示する
        isAhamo={isAhamo}
      >
        <JaHeading
          size={'page'}
          dangerouslySetInnerHTML={{ __html: acf?.page_headline_ja || title?.rendered || 'No title' }}
        />
      </DetailMain>
      {isNewLayout && (
        <TopArea>
          <div>
            {isAvailableAbstruct(acf)
              ? acf.abstruct.contents?.map((content, index) => (
                  <ProjectDetailAbstract key={index} heading={content?.heading || ''} text={content?.text || ''} />
                ))
              : null}
          </div>
          <TopAreaRightSide>
            {isAvailableAwards(acf) || acf?.awards ? (
              <TopAreaSection>
                <TopAreaSectionHeading>
                  <EnHeading size={'subSection'}>{'Awards'}</EnHeading>
                </TopAreaSectionHeading>
                <TopAreaAwardList itemCount={acf.awards && Array.isArray(acf.awards) ? acf.awards?.length : 0}>
                  {acf.awards?.map((award, index) => {
                    const foundAward = data.allShifterAwardsData.nodes.find(
                      awardHoge => htmlscd(awardHoge.title?.rendered) === award?.award[0]?.post_title
                    );
                    if (foundAward) {
                      return (
                        <Img
                          filename={foundAward?.acf?.logo ?? ''}
                          alt={`${foundAward?.title?.rendered} logo`}
                          key={index}
                          isContain
                        />
                      );
                    } else return null;
                  })}
                </TopAreaAwardList>
              </TopAreaSection>
            ) : null}
            {isAvailableAcf(acf) && isAvailableLinkArea(acf.link_area) && (
              <TopAreaSection>
                {acf.link_area.map((list, index) => (
                  <React.Fragment key={index}>
                    {list.links?.title && (
                      <TopAreaSectionHeading>
                        <EnHeading size={'subSection'}>{list.links?.title}</EnHeading>
                      </TopAreaSectionHeading>
                    )}
                    <TopAreaLinkList>
                      {list.links?.link_list?.map((item, index) => (
                        <Button key={index} href={item?.url ?? ''} blank={true}>
                          {item?.label}
                        </Button>
                      ))}
                    </TopAreaLinkList>
                  </React.Fragment>
                ))}
              </TopAreaSection>
            )}
          </TopAreaRightSide>
        </TopArea>
      )}

      {isAvailableHighlightsArea(acf) && isProjectEvaluateAreaContents(acf.highlights.contents) ? (
        <ProjectEvaluateArea
          layout={acf.highlights.layout ?? ''}
          image={acf.highlights.image ?? ''}
          contents={acf.highlights.contents}
          image_mask={acf.highlights.image_mask ?? ''}
          // FIXME: myJCBのみこのbooleanをONにする
          // hasNoteIcon
        />
      ) : null}

      {isAvailableProcess(acf) ? <ProjectDetailProcess process={acf.process} /> : null}

      {acf?.modules?.map((module, idx) => {
        if (
          module?.type === 'feature' &&
          isShifterProjectsJpDataAcfModulesFeature(module.feature) &&
          module?.feature?.headline &&
          module?.feature?.sub_contents &&
          module?.feature?.sub_contents &&
          module?.feature?.sub_contents?.length > 0
        ) {
          return (
            <DetailContentIncludingComponent
              key={`module-${idx}`}
              sideContent={module.feature?.sub_contents}
              isImgRight={module?.feature?.img_right || false}
            >
              <JaHeading
                size={'middle'}
                dangerouslySetInnerHTML={{
                  __html: module?.feature?.headline || 'NO TITLE',
                }}
                isInContents
              />
              <Text
                isDiv
                dangerouslySetInnerHTML={{
                  __html: module?.feature?.text || 'NO TEXT',
                }}
              />
            </DetailContentIncludingComponent>
          );
        }

        if (
          module?.type === 'feature' &&
          isShifterProjectsDataAcfModulesFeature(module.feature) &&
          module?.feature?.headline &&
          module?.feature?.pics &&
          module?.feature?.pics?.length > 0
        ) {
          return (
            <DetailContent
              key={`module-${idx}`}
              imgs={
                module.feature?.pics?.map(pic => {
                  return {
                    path: pic?.pic || `noimage/img-card-thumb.jpg`,
                    alt: '',
                  };
                }) as ImgsObject[]
              }
              isImgRight={module?.feature?.img_right || false}
            >
              <JaHeading
                size={'middle'}
                dangerouslySetInnerHTML={{
                  __html: module?.feature?.headline || 'NO TITLE',
                }}
                isInContents
              />
              <Text
                isDiv
                dangerouslySetInnerHTML={{
                  __html: module?.feature?.text || 'NO TEXT',
                }}
              />
            </DetailContent>
          );
        }
        if (module?.type === 'list_set' && (module?.list_set?.type === 'ol' || module.list_set?.type === 'ul')) {
          const { ul, ol } = module?.list_set;
          return (
            <ContentInner key={`module-${idx}`} spPadding>
              <ProjectListWrap>
                {ul && <List note txts={ul?.map(listItem => listItem?.li) as string[]}></List>}
                {ol && <List note ordered txts={ol?.map(listItem => listItem?.li) as string[]}></List>}
              </ProjectListWrap>
            </ContentInner>
          );
        }

        if (module?.type === 'basic_text' && module?.basic_text?.headline) {
          return (
            <ContentInner key={`module-${idx}`} spPadding>
              <TextSet>
                <JaHeading
                  size={'middle'}
                  dangerouslySetInnerHTML={{ __html: module?.basic_text.headline || 'No title' }}
                  isInContents
                />
                <Text isDiv dangerouslySetInnerHTML={{ __html: module?.basic_text.text || 'No title' }} />
              </TextSet>
            </ContentInner>
          );
        }

        if (module?.type === 'pic_double' && module?.pic_double && module?.pic_double.pic1) {
          return (
            <ContentWrapper key={`module-${idx}`}>
              <MediaGrid
                imgs={[
                  {
                    path: module?.pic_double.pic1 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_double.pic2 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                ]}
              />
            </ContentWrapper>
          );
        }

        if (module?.type === 'pic_triple' && module?.pic_triple && module?.pic_triple.pic1) {
          return (
            <ContentWrapper key={`module-${idx}`}>
              <MediaGrid
                imgs={[
                  {
                    path: module?.pic_triple.pic1 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 1,
                  },
                  {
                    path: module?.pic_triple.pic2 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_triple.pic3 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                ]}
              />
            </ContentWrapper>
          );
        }

        if (module?.type === 'pic_sextuple' && module?.pic_sextuple && module?.pic_sextuple.pic1) {
          return (
            <ContentWrapper key={`module-${idx}`}>
              <MediaGrid
                imgs={[
                  {
                    path: module?.pic_sextuple.pic1 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 1,
                  },
                  {
                    path: module?.pic_sextuple.pic2 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 1,
                  },
                  {
                    path: module?.pic_sextuple.pic3 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_sextuple.pic4 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_sextuple.pic5 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_sextuple.pic6 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                ]}
              />
            </ContentWrapper>
          );
        }

        if (module?.type === 'pic_carousel') {
          const srcs = module.pic_carousel?.visuals?.map(item => {
            return {
              path: item?.visual || `noimage/img-card-thumb.jpg`,
              media: item?.visual?.includes('.mp4') || item?.visual?.includes('.mov') ? 'video' : 'image',
              title: item?.caption,
            };
          });

          if (!srcs) return null;

          return (
            <GallerySlide
              key={`module-${idx}`}
              type={module.pic_carousel?.size === 'large' ? 'full' : undefined}
              contents={srcs as ContentsObject[]}
            />
          );
        }

        if (module?.type === 'pic_large' && module?.pic_large) {
          return <FullImg key={`module-${idx}`} imgpath={module?.pic_large || `noimage/img-card-thumb.jpg`} alt={''} />;
        }

        if (module?.type === 'video_large' && module?.video_large) {
          return (
            <FullVideo
              url={
                module?.video_large ||
                'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
              }
            />
          );
        }

        if (module?.type === 'youtube') {
          return (
            <ContentWrapper key={`module-${idx}`}>
              <TopicsSectionWrap>
                {module.youtube?.size === 'large' ? (
                  <Youtube id={module?.youtube.alternative_id || 'V86xKCqVsx0'} />
                ) : module.youtube?.size === 'small' ? (
                  <ContentInner>
                    <Youtube id={module?.youtube.alternative_id || 'V86xKCqVsx0'} />
                  </ContentInner>
                ) : null}
              </TopicsSectionWrap>
            </ContentWrapper>
          );
        }

        if (module?.type === 'visual_set') {
          if (module.visual_set?.size === 'large') {
            return (
              <ContentWrapper key={`module-${idx}`}>
                <TopicsSectionWrap>
                  {module?.visual_set?.visual?.includes('.mp4') || module?.visual_set?.visual?.includes('.mov') ? (
                    <Video
                      url={
                        module?.visual_set.visual ||
                        'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
                      }
                    />
                  ) : (
                    <Img
                      filename={module?.visual_set.visual || ''}
                      alt={''}
                      caption={module?.visual_set.caption || undefined}
                    />
                  )}
                </TopicsSectionWrap>
              </ContentWrapper>
            );
          }
          if (module.visual_set?.size === 'small') {
            return (
              <ContentWrapper key={`module-${idx}`}>
                <TopicsSectionWrap>
                  <ContentInner>
                    {module?.visual_set?.visual?.includes('.mp4') || module?.visual_set?.visual?.includes('.mov') ? (
                      <Video
                        url={
                          module?.visual_set.visual ||
                          'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
                        }
                      />
                    ) : (
                      <Img
                        filename={module?.visual_set.visual || ''}
                        alt={''}
                        caption={module?.visual_set.caption || undefined}
                      />
                    )}
                  </ContentInner>
                </TopicsSectionWrap>
              </ContentWrapper>
            );
          }
        }

        if (module?.type === 'pic_slider') {
          const srcs: GalleryLoopSlideType[] | undefined = module.pic_slider?.pics?.map(item => {
            return {
              path: item?.pic || `noimage/img-card-thumb.jpg`,
              media: item?.pic?.includes('.mp4') || item?.pic?.includes('.mov') ? 'video' : 'image',
            };
          });
          if (!srcs) return null;

          return (
            <GalleryLoopSlide
              key={`module-${idx}`}
              type={module.pic_slider?.size === 'large' ? 'full' : undefined}
              rtl={module.pic_slider?.rtl || undefined}
              contents={srcs}
            />
          );
        }

        if (module?.type === 'appeal') {
          const numbers = module?.appeal?.fact?.map(item => {
            return {
              title: item?.headline || 'No Title',
              number: item?.number || 'No Number',
              unit: item?.unit || null,
              isPlus: item?.change === '+',
              isMinus: item?.change === '-',
            };
          }) as NumbersObject[];

          return (
            <ProjectDetailNumbers
              key={`module-${idx}`}
              title={module.appeal?.label || 'No Title'}
              numbers={numbers}
              numbersCol={module.appeal?.layout === 'col1' ? 1 : 2}
            />
          );
        }

        if (module?.type === 'pic_and_messages') {
          const picMessages = module.pic_and_messages;
          return (
            <ProjectEvaluatePicAndMessages>
              <ProjectEvaluateWrapper height={messageHeight}>
                <ProjectEvaluatePic filename={picMessages.pic.url ?? ''} alt="" />
              </ProjectEvaluateWrapper>

              <ProjectEvaluateMessagesFirst ref={firstMessageRef}>
                <ProjectEvaluateMessageCard
                  text={picMessages.messages[0].text}
                  name={picMessages.messages[0].name}
                  role={picMessages.messages[0].role}
                  isDark={picMessages.messages[0].isDark}
                  isBorderGray
                />
              </ProjectEvaluateMessagesFirst>
              <ProjectEvaluateMessagesSecond ref={secondMessageRef}>
                <ProjectEvaluateMessageCard
                  text={picMessages.messages[1].text}
                  name={picMessages.messages[1].name}
                  role={picMessages.messages[1].role}
                  isDark={picMessages.messages[1].isDark}
                  isBorderGray
                />
              </ProjectEvaluateMessagesSecond>
            </ProjectEvaluatePicAndMessages>
          );
        }
        return null;
      })}

      <ProjectDetailOtherData
        client={acf?.client_abbr?.ja ? acf?.client_name?.ja || undefined : undefined}
        credit={acf?.credit?.map(credit => {
          return { title: credit?.role, data: credit?.member } as CreditObject;
        })}
        partner={acf?.credit_ptn?.map(creditPtn => {
          return {
            title: creditPtn?.role,
            data: creditPtn?.member,
          } as CreditObject;
        })}
        awards={
          (acf?.awards?.map(award => {
            return {
              award: award?.award ? award?.award[0]?.post_title : 'No Title',
              label: award?.award ? award?.award[0]?.post_title : 'No Title',
              win: award?.prize,
              to: award?.url,
            };
          }) as AwardProps[]) || undefined
        }
        isNewLayout={isNewLayout}
      />

      {showOtherProjects.length > 0 && (
        <RangeSlide perView={3} title={'Other Projects'} isplacedbottom isTitleSmall>
          {showOtherProjects.map(project => (
            <ProjectCard
              key={project.node?.id}
              size={'small'}
              spSize="small"
              imgpath={project?.node?.acf?.thumb || `noimage/img-card-thumb.jpg`}
              title={project?.node?.acf?.card_headline_ja || project?.node?.title?.rendered || 'NO TITLE'}
              client={project?.node?.acf?.client_abbr?.ja || undefined}
              work={project?.node?.acf?.product?.ja || undefined}
              tags={project?.node?.acf?.outline as string[]}
              to={`/projects/${project?.node?.slug}`}
            />
          ))}
        </RangeSlide>
      )}
      <ProjectsBackToWrapBottom to={'/projects'}></ProjectsBackToWrapBottom>
      <InquiryBlock />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query ProjectsDetail($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    otherProjectsJp: allShifterProjectsJpData(
      sort: { fields: date, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } }, slug: { ne: $slug } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            page_headline_ja
            card_headline_ja
            services {
              post_title
            }
            thumb
            client_name {
              en
              ja
            }
            client_abbr {
              en
              ja
            }
            product {
              ja
              en
            }
            outline
          }
        }
      }
    }
    otherProjects: allShifterProjectsData(
      sort: { fields: date, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } }, slug: { ne: $slug } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            page_headline_ja
            card_headline_ja
            services {
              post_title
            }
            thumb
            client_name {
              en
              ja
            }
            client_abbr {
              en
              ja
            }
            product {
              ja
              en
            }
            outline
          }
        }
      }
    }
    shifterProjectsJpData(slug: { eq: $slug }) {
      acf {
        abstruct {
          contents {
            heading
            text
          }
        }
        awards {
          award {
            post_title
          }
          prize
          url
        }
        client_abbr {
          en
          ja
        }
        product {
          ja
          en
        }
        client_name {
          en
          ja
        }
        credit {
          member
          role
        }
        credit_ptn {
          member
          role
        }
        highlights {
          layout
          image
          image_mask
          contents {
            type
            value {
              number
              unit
              text
              notes {
                text
              }
            }
            comment {
              heading
              text
            }
            message {
              text
              name
              role
            }
          }
        }
        process {
          title
          text
        }
        page_headline_ja
        card_headline_ja
        headline_en
        kv
        kv_mask
        kv_sp
        kv_mask_sp
        launch
        modules {
          type
          appeal {
            fact {
              change
              number
              headline
              unit
            }
            text_en
            text
            layout
            label
            headline_en
            headline
          }
          basic_text {
            headline
            headline_en
            text
            text_en
          }
          feature {
            sub_contents {
              type
              value {
                number
                unit
                text
              }
              comment {
                heading
                text
              }
              message {
                text
                name
                role
                isDark
              }
              pic
            }
            headline
            headline_en
            img_right
            text
            text_en
          }
          pic_carousel {
            visuals {
              visual
              caption
            }
            size
          }
          pic_slider {
            pics {
              pic
            }
            rtl
            size
          }
          pic_double {
            pic1
            pic2
          }
          pic_triple {
            pic1
            pic2
            pic3
          }
          pic_sextuple {
            pic2
            pic1
            pic3
            pic4
            pic5
            pic6
          }
          visual_set {
            size
            visual
            caption
          }
          pic_large
          video_large
          youtube {
            size
            alternative_id
          }
          list_set {
            ol {
              li
            }
            ul {
              li
            }
            type
          }
          pic_and_messages {
            pic {
              url
            }
            messages {
              text
              name
              role
              isDark
            }
          }
        }
        overview_text {
          ja
          en
        }
        product {
          ja
          en
        }
        services {
          post_title
        }
        studios {
          post_title
        }
        url
        top_show_area
        thumb
        outline
        link_area {
          links {
            link_list {
              label
              url
            }
            title
          }
        }
      }
      slug
      title {
        rendered
      }
    }
    shifterProjectsData(slug: { eq: $slug }) {
      acf {
        awards {
          award {
            post_title
          }
          prize
          url
        }
        client_abbr {
          en
          ja
        }
        product {
          ja
          en
        }
        client_name {
          en
          ja
        }
        credit {
          member
          role
        }
        credit_ptn {
          member
          role
        }
        page_headline_ja
        card_headline_ja
        headline_en
        kv
        kv_sp
        launch
        modules {
          type
          appeal {
            fact {
              change
              number
              headline
              unit
            }
            text_en
            text
            layout
            label
            headline_en
            headline
          }
          basic_text {
            headline
            headline_en
            text
            text_en
          }
          feature {
            pics {
              pic
            }
            headline
            headline_en
            img_right
            text
            text_en
          }
          pic_carousel {
            visuals {
              visual
              caption
            }
            size
          }
          pic_slider {
            pics {
              pic
            }
            rtl
            size
          }
          pic_double {
            pic1
            pic2
          }
          pic_triple {
            pic1
            pic2
            pic3
          }
          pic_sextuple {
            pic2
            pic1
            pic3
            pic4
            pic5
            pic6
          }
          visual_set {
            size
            visual
            caption
          }
          pic_large
          video_large
          youtube {
            size
            alternative_id
          }
          list_set {
            ol {
              li
            }
            ul {
              li
            }
            type
          }
        }
        overview_text {
          ja
          en
        }
        product {
          ja
          en
        }
        services {
          post_title
        }
        studios {
          post_title
        }
        url
        top_show_area
        thumb
        outline
      }
      slug
      title {
        rendered
      }
    }
    allShifterAwardsData {
      nodes {
        acf {
          logo
        }
        title {
          rendered
        }
      }
    }
  }
`;
