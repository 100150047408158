import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Img from 'components/atoms/img';
import { ProjectEvaluateMessageCard } from 'components/molecules/projectEvaluateMessageCard';
import { ProjectEvaluateNumCard } from 'components/molecules/projectEvaluateNumCard';
import { ProjectEvaluateTextCard } from 'components/molecules/projectEvaluateTextCard';
import { TextSet } from 'components/molecules/textSet';
import React from 'react';
import { contentWrapper } from 'style/mixin';
import sizes from 'style/sizes';
import { mq, headerHeightPc } from 'style/variables';
// eslint-disable-next-line @typescript-eslint/camelcase
import { Maybe, ShifterProjectsJpDataAcfModulesFeatureSub_Contents } from 'types/graphql-types';

type DetailContentIncludingComponent = {
  // eslint-disable-next-line @typescript-eslint/camelcase
  sideContent: Maybe<Array<Maybe<ShifterProjectsJpDataAcfModulesFeatureSub_Contents>>>;
  isImgRight?: boolean;
};

const Container = styled.div<DetailContentIncludingComponent>`
  opacity: 1;

  ${mq.onlypc} {
    ${contentWrapper}
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${sizes.margin[40]};
    margin-bottom: ${sizes.margin[180]};
    direction: ${props => (props.isImgRight ? 'ltr' : 'rtl')};
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[100]};
  }
`;

const StickyArea = styled.div<DetailContentIncludingComponent>`
  direction: ltr;
  ${mq.onlypc} {
    height: fit-content;
    min-width: 700px;
    ${props =>
      props.isImgRight
        ? css`
            margin-left: 0;
            margin-right: calc((1160px - 100vw) / 2);
          `
        : css`
            margin-right: 0;
            margin-left: calc((1160px - 100vw) / 2);
          `}
  }
  ${mq.onlypcmin} {
    ${props => (props.isImgRight ? `margin-right` : `margin-left`)}: -40px;
  }
  ${mq.onlysp} {
    margin-top: ${sizes.margin[64]};
  }
  & > div {
    & + div {
      margin-top: 0px;
    }
  }
`;

const MessageCard = styled.div`
  & + & {
    margin-top: -1px;
  }
`;

const TextArea = styled.div`
  position: relative;
  direction: ltr;
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[32]};
  }
`;

const TextAreaInner = styled.div`
  ${mq.onlypc} {
    position: sticky;
    top: ${headerHeightPc}px;
  }
`;

export const DetailContentIncludingComponent: React.FC<DetailContentIncludingComponent> = props => {
  return (
    <Container {...props}>
      <TextArea>
        <TextAreaInner>
          <TextSet>{props.children}</TextSet>
        </TextAreaInner>
      </TextArea>
      <StickyArea {...props}>
        {props.sideContent?.map((item, idx) =>
          (() => {
            switch (item?.type) {
              case 'value':
                return (
                  <ProjectEvaluateNumCard
                    number={item.value?.number || ''}
                    unit={item.value?.unit || ''}
                    text={item.value?.text || ''}
                    notes={item.value?.notes || []}
                  />
                );
              case 'comment':
                return <ProjectEvaluateTextCard heading={item.comment?.heading} text={item.comment?.text} notes={[]} />;
              case 'message':
                return (
                  <MessageCard>
                    <ProjectEvaluateMessageCard
                      text={item.message?.text || ''}
                      name={item.message?.name || ''}
                      role={item.message?.role || ''}
                      isDark={item.message?.isDark || false}
                      isBorderGray
                    />
                  </MessageCard>
                );
              case 'pic':
                return <Img key={idx} filename={item.pic ?? ''} alt={item.pic ?? ''} />;
              default:
                return null;
            }
          })()
        )}
      </StickyArea>
    </Container>
  );
};
